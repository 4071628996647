// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { useEffect } from "react";
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useLocation } from "@remix-run/react";
import { pageview } from "./utils";
import { Navbar } from "~/components/navbar";
import styles from "~/styles/tailwind.css";
export const meta = () => {
  return [{
    name: "viewport",
    content: "width=device-width,initial-scale=1"
  }, {
    title: "Feedgenie"
  }];
};
export const links = () => {
  return [{
    rel: "stylesheet",
    href: styles
  }];
};
export const loader = async () => {
  return process.env.GA_TRACKING_ID;
};
const Document = ({
  children,
  title = "Feedgenie"
}) => {
  _s();
  const location = useLocation();
  const gaTrackingId = useLoaderData();
  useEffect(() => {
    if (gaTrackingId?.length) {
      pageview(location.pathname, gaTrackingId);
    }
  }, [location, gaTrackingId]);
  return <html lang="en" className="dark">
      <head>
        <meta charSet="utf-8" />
        <Meta />
        <title>{title}</title>
        <Links />
      </head>

      <body className="bg-feedgenie-1 font-robotomono">
        {process.env.NODE_ENV === "development" || !gaTrackingId ? null : <>
            <script async src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`} />

            <script async id="gtag-init" dangerouslySetInnerHTML={{
          __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${gaTrackingId}', {
                  page_path: window.location.pathname,
                });
              `
        }} />

          </>}


        {children}
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>;
};
_s(Document, "aglx3UQRpIz3HgnMcAxVE/iYWy0=", false, function () {
  return [useLocation, useLoaderData];
});
_c = Document;
const App = () => {
  _s2();
  const location = useLocation();
  if (location.pathname === "/") {
    return <Document>
        <Outlet />
      </Document>;
  }
  return <Document>
      <Navbar />
      <Outlet />
    </Document>;
};
_s2(App, "pkHmaVRPskBaU4tMJuJJpV42k1I=", false, function () {
  return [useLocation];
});
_c2 = App;
const ErrorDocument = ({
  children,
  title = "Feedgenie"
}) => {
  return <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <Meta />
        <title>{title}</title>
        <Links />
      </head>

      <body className="bg-feedgenie-1 font-robotomono">
        {children}
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>;
};
_c3 = ErrorDocument;
export function ErrorBoundary({
  error
}) {
  return <ErrorDocument title="Uh-oh!">
      <div className="flex h-screen flex-col items-center justify-center text-white">
        <h1>An error has occured, please contact support.</h1>
        <pre>{error?.message}</pre>
      </div>
    </ErrorDocument>;
}
_c4 = ErrorBoundary;
export default App;
var _c, _c2, _c3, _c4;
$RefreshReg$(_c, "Document");
$RefreshReg$(_c2, "App");
$RefreshReg$(_c3, "ErrorDocument");
$RefreshReg$(_c4, "ErrorBoundary");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;