// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/navbar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/navbar.tsx");
  import.meta.hot.lastModified = "1724962854541.6423";
}
// REMIX HMR END

import { Link, useLocation } from "@remix-run/react";
import { Icon } from "./icon";
export const Navbar = () => {
  _s();
  const location = useLocation();
  return <nav className="flex h-14 w-full items-center justify-between py-4 px-8 text-white shadow">
      <div>
        <Link to="/dashboard" className="text-lg font-bold">
          <img src="/images/logo.png" alt="feedgenie logo" />
        </Link>
      </div>

      {["/login", "/signup"].includes(location.pathname) ? null : <div className="flex items-center">
          <Link to="/profile/appearance" prefetch="intent">
            <Icon name="profile" />
          </Link>
        </div>}
    </nav>;
};
_s(Navbar, "pkHmaVRPskBaU4tMJuJJpV42k1I=", false, function () {
  return [useLocation];
});
_c = Navbar;
var _c;
$RefreshReg$(_c, "Navbar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;